<template>
  <div>
    <b-row
      class="mb-50"
      no-gutters
      align-v="center"
    >
      <b-col>
        <h3 class="mb-0">
          Address
        </h3>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="primary"
          size="sm"
          @click="showAddress = false"
        >
          <feather-icon
            icon="PlusIcon"
            size="18"
          />
        </b-button>
      </b-col>
    </b-row>
    <hr class="dropdown-divider">
    <b-table
      v-if="showAddress"
      :items="items"
      responsive
      hover
    />
    <b-form v-if="!showAddress">
      <b-row no-gutters>
        <b-col cols="4">
          <b-form-group
            label="Country"
            label-for="v-country"
          >
            <b-form-select
              id="v-country"
              v-model="selectCountry"
            >
              <b-form-select-option :value="null">
                --
              </b-form-select-option>
              <b-form-select-option value="a">
                Country A
              </b-form-select-option>
              <b-form-select-option value="b">
                Country B
              </b-form-select-option>
              <b-form-select-option value="c">
                Country C
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="4"
          class="pl-50 pr-50"
        >
          <b-form-group
            label="Government"
            label-for="v-government"
          >
            <b-form-select
              id="v-government"
              v-model="selectedGovernment"
            >
              <b-form-select-option :value="null">
                --
              </b-form-select-option>
              <b-form-select-option value="a">
                Government A
              </b-form-select-option>
              <b-form-select-option value="b">
                Government B
              </b-form-select-option>
              <b-form-select-option value="c">
                Government C
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="City"
            label-for="v-city"
          >
            <b-form-select
              id="v-city"
              v-model="selectedCity"
            >
              <b-form-select-option :value="null">
                --
              </b-form-select-option>
              <b-form-select-option value="a">
                City A
              </b-form-select-option>
              <b-form-select-option value="b">
                City B
              </b-form-select-option>
              <b-form-select-option value="c">
                City C
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group
            label="Line Address 1"
            label-for="line-address-1"
          >
            <b-form-input
              id="line-address-1"
              v-model="lineAddressFirst"
              placeholder="Line Address 1"
              required
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
          class="pl-50"
        >
          <b-form-group
            label="Zip code"
            label-for="zip-code"
          >
            <b-form-input
              id="zip-code"
              v-model="zipCode"
              placeholder="Zip code"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Line Address 2"
            label-for="line-address-2"
          >
            <b-form-input
              id="line-address-2"
              v-model="lineAddressSeconde"
              placeholder="Line Address 2"
              required
            />
          </b-form-group>
        </b-col>
        <!--<b-col cols="12">
          <b-form-group label="Comment" label-for="comment">
            <b-form-textarea
              id="comment"
              v-model="comment"
              placeholder="Comment"
            />
          </b-form-group>
        </b-col>-->
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox />
            <span>Default</span>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox />
            <span>Shipping</span>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox />
            <span>Billing</span>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="text-right">
        <b-button
          variant="primary"
          class="mr-1"
          @click="showAddress = true"
        >Cancel</b-button>
        <b-button
          variant="primary"
          @click="showAddress = true"
        >
          Save
        </b-button>
      </div>
    </b-form>
    <hr class="dropdown-divider">
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  // BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    // BFormTextarea,
    BFormCheckbox,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          country: "Egypt",
          city: "Assiut",
          default: "Assiut",
          type: "Shipping",
        },
        {
          id: 2,
          country: "Egypt",
          city: "Assiut",
          default: "Assiut",
          type: "Billing",
        },
        {
          id: 3,
          country: "Egypt",
          city: "Assiut",
          default: "Assiut",
          type: "Shipping / Billing",
        },
      ],
      showAddress: true,
      selectCountry: null,
      selectedGovernment: null,
      selectedCity: null,
      lineAddressFirst: "",
      lineAddressSeconde: "",
      zipCode: "",
      comment: "",
    };
  },
  methods: {},
};
</script>

<style scoped>
</style>
